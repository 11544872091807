<template>
  <v-app>
    <dashboard-core-app-bar />

    <div v-if="drawerVisible()">
      <dashboard-core-drawer />
    </div>

    <dashboard-core-view />

    <div v-if="drawerVisible()">
      <dashboard-core-footer />
    </div>
  </v-app>
</template>

<script>
export default {
  name: 'DashboardIndex',

  components: {
    DashboardCoreAppBar: () => import('./components/core/AppBar'),
    DashboardCoreDrawer: () => import('./components/core/Drawer'),
    DashboardCoreView: () => import('./components/core/View'),
    DashboardCoreFooter: () => import('./components/core/Footer')
  },

  data: () => ({
    expandOnHover: false
  }),

  methods: {
    drawerVisible() {
      let route = this.$route?.name
      if (route == 'Dashboard Login' || route == 'Operator Login') {
        return false
      }
      if (this.$store.state?.session?.prefix?.startsWith('/operator/')) {
        return false
      }

      return true
    }
  }
}
</script>
